import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "HashiCorp Vault - Secrets Management "
const pageTitle = "HashiCorp Vault - Secrets Management"
const subTitle = "Cloud Infrastructure "
const para = (
  `<div>
    <h3>Our approach</h3>
    <p>
      Reviewed applications and databases in urgent need of dynamic rotation of
      credentials.This assessment involved a deep dive with customer teams,
      identifying requirements that provided vital inputs for our solution
      design. This included a review of the existing on-prem and cloud
      architecture.
    </p>
  </div>`
)
const blockquote = `"Integrate security into every phase of your cloud journey- Put zero trust into action."`

const cardItems = [
  {
    id: 1,
    heading: `Challenges this customer was facing:`,
    listItems: (
      <>
        <li>
          <Icon.Square /> The database/system administrators and developers were
          storing the database credentials on their local devices.
        </li>
        <li>
          <Icon.Square /> These database credentials were stored in plain text
          and not encrypted.
        </li>
        <li>
          <Icon.Square />
          No policy enforcement of password rotation.
        </li>
        <li>
          <Icon.Square />
          Database passwords were scattered throughout the enterprise in
          undocumented locations.
        </li>
        <li>
          <Icon.Square />
          Some databases had unchanged passwords for extended periods of time.
        </li>
        <li>
          <Icon.Square />
          There were database passwords stored on source control repos that had
          no audit trails.
        </li>
        <li>
          <Icon.Square />
          Expensive compliance audit failures.
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `Solution Overview`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          High-Level target state Vault architecture (including DR)
        </li>
        <li>
          <Icon.Square /> Summary of Phase 1 Vault architecture, including
          secrets engines, namespace design, authentication design, policy
          design, workflows, and overall Vault configuration{" "}
        </li>
        <li>
          <Icon.Square />
          High-level documentation of planned future implementation phases,
          where applicable (e.g.,future functionality expansion such as new
          secrets engines)
        </li>
        <li>
          <Icon.Square />
          DevSecOps review (CI/CD workflows, security, IAM, network, etc.)
        </li>
        <li>
          <Icon.Square />
          Management presentation summarizing findings including a list of
          assumptions issues/concerns System
        </li>
        <li>
          <Icon.Square /> Database admin and application dev training of Vault
          API, UI and CLI
        </li>
      </>
    ),
  },
  {
    id: 3,
    heading: `Service Benefits`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Admins and developers relieved of the burden of secure database
          password management, as Vault now takes care of dynamically rotating
          passwords, while providing seamless role-based, role-appropriate
          access .
        </li>
        <li>
          <Icon.Square />
          Greatly improved application and database security, once developers
          and admins are trained to use Vault's API to store and retrieve
          passwords from the Vault.
        </li>
        <li>
          <Icon.Square />
          Vastly improved access control, auditing and oversight of database
          secrets.
        </li>
        <li>
          <Icon.Square />
          Automation/integration of secrets management, reducing overhead, costs
          and potential for pilot errors.
        </li>
        <li>
          <Icon.Square />
          Regulatory (e.g., FIPS) compliance capability.
        </li>
      </>
    ),
  },
  {
    id: 4,
    heading: `Service Timeline and High-Level Milestones`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Assessment phase: 6-8 weeks.
        </li>
        <li>
          <Icon.Square />
          Design phase: 2-4 weeks.
        </li>
        <li>
          <Icon.Square />
          Implementation phase (key phase 1 users): 8-10 months.
        </li>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />

)
export const query = graphql`
  query ThirdQuery {
    casedetailsJson(slug: { eq: "hashicorp-vault" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
